.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: 13;
}

.psheader {
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.psheader>.navigation>.navigation-menu>ul>li>button {
    color: #000 !important;
}

.psheader>.navigation>.navigation-menu>ul>li>a {
    color: #000 !important;
}

.psheader>.navigation>.navigation-menu>ul>li>.dropdown {
    color: #000 !important;

}

.psheader>.navigation>.logo>a>.img {
    background-image: url(../../Assets/Img/34logo.svg) !important;
}

.header.sticky {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    z-index: 15;

    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
}

@media screen and (min-width:0) and (max-width:768px) {
    .header.sticky {
        top: -2px;
    }
}

.header>.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 15px;
    position: relative;
}

.header>.navigation>.hamburger {
    display: none;
    border: 0;
    border-radius: 50%;
    background-image: linear-gradient(90deg, #f34c2d, #e1355b);
    cursor: pointer;
    padding: 5px;
}

.header>.navigation>.hamburger>svg {
    width: 25px;
    height: 25px;
}

.header>.navigation>.logo {
    width: 200px;
    height: 100%;
}

.header>.navigation>.logo>a {
    display: block;
    width: 100%;
    height: 100%;
}

.header>.navigation>.logo>a>.img {
    background-image: url(../../Assets/Img/Logo.svg);
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.header>.navigation>.navigation-menu {
    height: 100%;
}

.header>.navigation>.navigation-menu>ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
    padding: 0;
}

.header>.navigation>.navigation-menu>ul>li {
    list-style: none;
    height: 100%;
    display: block;
    padding: 20px 0px;
    transition: 0.3s ease all;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    position: relative;
    font-family: var(--primary-font-family);
    font-size: 15px;

}

.header>.navigation>.navigation-menu>ul>li:hover {
    border-bottom: 2px solid #fff;
}

.header>.navigation>.navigation-menu>ul>li>button,
a {
    height: 100%;
    display: block;
    word-break: break-all;
    color: #ffffff;
    background: none;
    border: none;
    padding: 0;
    text-decoration: none;
}

.header>.navigation>.navigation-menu>ul>li>a:hover {
    height: 100%;
    display: block;
    word-break: break-all;
    color: #fff;
    background: none;
    border: none;

}

.header>.navigation>.navigation-menu>ul>li>.dropdown {
    display: flex;
    align-items: center;
    color: #ffffff;
}

.header>.navigation>.navigation-menu>ul>li>.dropdown>i {
    margin-left: 10px;
}

.header>.navigation>.navigation-menu>ul>li>.dropdown>.dropdown-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    width: 200px;
    background-color: #fff;
    display: none;
    flex-direction: column;
    border-radius: 6px;
    transition: 0.3s ease all;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    z-index: 1;
}

.header>.navigation>.navigation-menu>ul>li>.dropdown:hover>.dropdown-content {
    display: flex;
}

.header>.navigation>.navigation-menu>ul>li>.dropdown>.dropdown-content>button {
    color: black;
    padding: 10px 15px;
    text-decoration: none;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 15px;
    transition: 0.3s ease all;
    display: block;
    border: none;
    text-align: start;
}

.header>.navigation>.navigation-menu>ul>li>.dropdown>.dropdown-content>button:hover {
    background: #ccc;
}

.header>.navigation>.navigation-menu>ul>li>.dropdown>.dropdown-content>button:last-child {
    margin-bottom: 0px;
}

.header.sticky>.navigation>.logo>a>.img {
    background-image: url(../../Assets/Img/34logo.svg);
}

.header.sticky>.navigation>.navigation-menu>ul>li:hover {
    border-bottom: 2px solid #000;
}

.header.sticky>.navigation>.navigation-menu>ul>li>button {
    color: #000;
    padding: 0;
}

.header.sticky>.navigation>.navigation-menu>ul>li>a {
    color: #000;
}

.header.sticky>.navigation>.navigation-menu>ul>li>a:hover {
    color: #000;
}


.header.sticky>.navigation>.navigation-menu>ul>li>.dropdown>i,
.header.sticky>.navigation>.navigation-menu>ul>li>.dropdown {
    color: #000000;
}

.BackGround {
    background-color: #00000023;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .header>.navigation>.navigation-menu {
        width: 100%;
    }

    .header>.navigation>.navigation-menu>ul {
        gap: 40px;
        justify-content: flex-end;
    }

}

@media screen and (min-width:769px) and (max-width:991px) {
    .header>.navigation>.navigation-menu {
        width: 100%;
    }

    .header>.navigation>.navigation-menu>ul {
        gap: 30px;
        justify-content: flex-end;
    }

}

@media screen and (min-width:0px) and (max-width:768px) {
    .header {
        background-color: #fff;
    }

    .header>.navigation {
        padding: 0 10px;
        height: 70px;
    }

    .header>.navigation>.logo>a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header>.navigation>.logo>a>.img {
        background-image: url(../../Assets/Img/34logo.svg);
        background-position: left;
    }

    .header>.navigation>.hamburger {
        display: block;
    }

    .header>.navigation>.navigation-menu {
        display: none;
    }

    .header>.navigation>.navigation-menu.expanded {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 5px;
        border-top: 1px solid #ccc;
        height: auto;
        box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    }

    .header>.navigation>.navigation-menu.expanded>ul {
        display: block;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li {
        flex-direction: column;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 6px;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li:hover {
        background-color: #ccc;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li:last-child {
        margin-bottom: 0px;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li>button {
        color: #000;
        padding: 0;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li>a {
        color: #000;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li>.dropdown {
        color: #000;
    }

    .header>.navigation>.navigation-menu.expanded>ul>li>.dropdown>.dropdown-content {
        z-index: 11;
        width: 100%;
        padding: 5px;
    }

    .header>.navigation>.navigation-menu>ul>li>.dropdown>.dropdown-content>button {
        padding: 10px;
        margin-bottom: 10px;
    }
}