.header-blogs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: 13;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    button {
        color: #000 !important; 
    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 15px;
        position: relative;
        background-color: #fff;
        .logo {
            width: 200px;
            height: 100%;
            a {
                display: block;
                width: 100%;
                height: 100%;
                .img {
                    background-image: url(../../../Assets/img/logo2.png);
                    width: 100%;
                    height: 50px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }
    }
    .navigation-menu {
        height: 100%;
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            height: 100%;
            padding: 0;
            li {
                list-style: none;
                height: 100%;
                display: block;
                padding: 20px 0px;
                transition: 0.3s ease all;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                position: relative;
                font-family: var(--primary-font-family);
                font-size: 15px;

                &:hover {
                    border-bottom: 2px solid #fff;
                }
                button,
                a {
                    height: 100%;
                    display: block;
                    word-break: break-all;
                    color: #ffffff;
                    background: none;
                    border: none;
                    padding: 0;
                    text-decoration: none;

                    &:hover {
                        height: 100%;
                        display: block;
                        word-break: break-all;
                        color: #fff;
                        background: none;
                        border: none;
                    }
                }
            }
            @media screen and (min-width: 992px) and (max-width: 1199px) {
                gap: 40px;
                justify-content: flex-end;
            }
        }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            width: 100%;
        }
    }
}

// @media screen and (min-width: 769px) and (max-width: 991px) {
//     .header-blogs > .navigation > .navigation-menu {
//         width: 100%;
//     }

//     .header-blogs > .navigation > .navigation-menu > ul {
//         gap: 30px;
//         justify-content: flex-end;
//     }
// }

// @media screen and (min-width: 0px) and (max-width: 768px) {
//     .header-blogs {
//         background-color: #fff;
//     }

//     .header-blogs > .navigation {
//         padding: 0 10px;
//         height: 70px;
//     }

//     .header-blogs > .navigation > .logo > a {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }

//     .header-blogs > .navigation > .logo > a > .img {
//         // background-image: url(../../Assets/Img/34logo.svg);
//         background-position: left;
//     }

//     .header-blogs > .navigation > .navigation-menu {
//         display: none;
//     }
// }
