/* Filename - index.css */
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas,
		"Courier New", monospace;
}

#root {
	position: relative;
}

.page {
	height: 30px;
	width: 300px;
	font-style: italic;
	margin-top: 20px;
	text-align: center;
	padding: 70px 0;
	font-size: 2rem;
	font-weight: 800;
}

#start {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#start div:nth-child(2n) {
	background-color: rgb(6, 71, 6);
	color: white;
}


 