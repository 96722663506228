#Login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(90deg, #f34c2d, #e1355b);

    .Login-box {
        width: 380px;
        height: 360px;
        background-color: rgb(255, 255, 255);
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        border-radius: 5px;

        .header-text {
            margin: 0 auto;

            h1 {
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                background-image: linear-gradient(90deg, #f34c2d, #e1355b);
            }
        }

        .Login-form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .input {
                .p-float-label {
                    input {
                        width: 100%;
                        // background-image: linear-gradient(90deg, #f34c2d26, #e1355b1a);
                    }
                    label {
                        margin-top: -0.5rem;
                    }
                    .p-password {
                        width: 100%;
                        display: flex;
                        justify-content: end;

                        .p-icon {
                            right: 0.75rem;
                            margin-top: -.5rem;
                        }
                    }
                }
            }
            button {
                border: none;
                padding: 12px;
                background-image: linear-gradient(90deg, #f34c2d, #e1355b);
                border-radius: 5px;
                color: #fff;
            }
        }
    }
}

.p-float-label {
    input:focus ~ label,
    input:-webkit-autofill ~ label,
    input.p-filled ~ label,
    .p-password ~ label,
    textarea:focus ~ label,
    textarea.p-filled ~ label,
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label,
    .p-tooltip-target-wrapper ~ label {
        top: -1% !important;
        font-size: 12px !important;
        padding: 2px 5px !important;
        background: #fff !important;
        width: auto !important;
        border-radius: 3px;
    }
}

.p-inputtext {
    &:enabled {
        &:hover {
            box-shadow: none !important;
            border-color: #535353 !important;
        }
    }

    &:focus {
        border-color: #535353 !important;
        box-shadow: none !important;
    }

    // &.p-invalid {
    //     border-color: #e24c4c !important;

    //     &:focus {
    //         border-color: #e24c4c !important;
    //     }

    //     &:enabled:hover {
    //         border-color: #e24c4c !important;
    //     }
    // }
}
