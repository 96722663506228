@font-face {
    font-family: 'LEMONMILK-Bold';
    src: url(./Assets/font/LEMONMILK-Bold.otf);
}

:root {
    --primary-font-family: 'Poppins', sans-serif;
    --primary-ps-pro: "LEMONMILK-Bold"
}

::selection {
    background-color: #E33855;
    color: rgb(255, 255, 255);
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


::-webkit-scrollbar {
    height: 0;
    overflow-y: hidden;
    width: 0;
}

.p-invaild {
    border: 1px solid red !important;
}