.page404 {
    .page404-img {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 70vh;
        }
    }

    .page404-text {
        text-align: center;
        p {
            padding: 10px;
        }
        .page404-btn {
            margin-top: 1em;
            background-image: linear-gradient(90deg, #f34c2d, #e1355b);
            border: none;
        }
    }
}
