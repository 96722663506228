@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&family=Hind:wght@400;600;700&family=Inconsolata:wght@700&family=PT+Serif&family=Poppins&family=Righteous&family=Roboto&family=Roboto+Slab:wght@500&display=swap");

* {
    margin: 0;
    font-family: var(--primary-font-family);
}

// @keyframes shine {
//     0% {
//         background-position: 0;
//     }

//     60% {
//         background-position: 600px;
//     }

//     100% {
//         background-position: 600px;
//     }
// }

.time {
    width: 100%;
    height: 60px;
    background-color: black;
}

.officetime {
    color: white;
    text-align: center;
    font-size: 21px;
    padding-top: 15px;
}

ul.p-menubar-root-list {
    right: 15px !important;
    position: absolute;
}

.p-menubar.p-component {
    height: 67px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .box {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .box {
        display: block;
        height: 100%;
    }

    .box img {
        width: 146px !important;
        margin-left: 12px !important;
        margin-top: 10px !important;
    }
}

#carouselExampleIndicators {
    position: relative;
}

.home_btn:hover {
    color: #fff;
}
.home_btn {
    display: inline-block;
}

.fordemo {
    position: fixed;
    background-repeat: no-repeat;
    z-index: 99;
    cursor: pointer;
    right: 2%;
    bottom: 5%;
    .demoimg {
        width: 110px;
        @media screen and (min-width: 0px) and (max-width: 767px) {
            width: 100px;
        }
    }
}

.firstslider {
    position: absolute;
    font-size: 35px;
    font-weight: 700;
    line-height: 0.89;
    color: #ffffff;
    letter-spacing: 0px;
    z-index: 11;
    top: 50%;
    left: 10%;
    transform: translate(-10%, -50%);
}

.machine_text {
    letter-spacing: 0;
    font-weight: 300;
    font-size: 33px;
}

.firstslider3 {
    position: absolute;
    margin-top: 335px;
    margin-left: 150px;
    font-size: 32px;
    font-weight: 400;
    line-height: 0.89;
    color: #ffffffab;
    letter-spacing: 10px;
    z-index: 11;
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .firstslider {
        position: absolute;
        font-weight: 700;
        line-height: 0.89;
        color: #ffffff;
        letter-spacing: 0px;

        font-size: 25px;
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%);
    }

    .firstslider3 {
        position: absolute;
        margin-top: 249px;
        margin-left: 150px;
        font-size: 24px;
        font-weight: 400;
        line-height: 0.89;
        color: #ffffffab;
        letter-spacing: 5px;
    }
}

@media screen and (min-width: 925px) and (max-width: 1024px) {
    .firstslider {
        position: absolute;
        font-size: 25px;
        font-weight: 700;
        line-height: 0.89;
        color: #ffffff;
        letter-spacing: 10px;
    }

    .firstslider3 {
        position: absolute;
        margin-top: 222px;
        margin-left: 29px;
        font-size: 18px;
        font-weight: 400;
        line-height: 0.89;
        color: #ffffffab;
        letter-spacing: 5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 925px) {
    .firstslider {
        position: absolute;
        font-size: 25px;
        font-weight: 600;
        line-height: 0.89;
        color: #ffffff;
        letter-spacing: 8px;
    }

    .machine_text {
        letter-spacing: 0;
        font-weight: 300;
        font-size: 23px;
    }

    .firstslider3 {
        position: absolute;
        margin-top: 157px;
        margin-left: 29px;
        font-size: 18px;
        font-weight: 400;
        line-height: 0.89;
        color: #ffffffab;
        letter-spacing: 5px;
    }
}

@media screen and (min-width: 555px) and (max-width: 768px) {
    .firstslider {
        position: absolute;
        margin-top: 27px;
        margin-left: 29px;
        font-size: 25px;
        font-weight: 600;
        line-height: 0.89;
        color: #ffffff;
        letter-spacing: 8px;
    }

    .machine_text {
        letter-spacing: 0;
        font-weight: 300;
        font-size: 23px;
    }

    .firstslider3 {
        position: absolute;
        margin-top: 106px;
        margin-left: 29px;
        font-size: 12px;
        font-weight: 400;
        line-height: 0.89;
        color: #ffffffab;
        letter-spacing: 5px;
    }
}

@media screen and (min-width: 0px) and (max-width: 555px) {
    .firstslider {
        position: absolute;
        font-size: 28px;
        line-height: 1.2;
        color: #ffffff;
        letter-spacing: 2px;
    }

    .machine_text {
        letter-spacing: 0;
        font-weight: 300;
        font-size: 18px;
    }

    .firstslider3 {
        position: absolute;
        font-size: 9px;
        font-weight: 400;
        line-height: 0.2;
        color: #ffffffab;
        letter-spacing: 2px;
    }
}

@media screen and (max-width: 768px) {
    .box img {
        width: 130px !important;
        margin-left: 7px !important;
        margin-top: 7px !important;
    }

    .time {
        width: 100%;
        height: 0px;
        background-color: black;
    }

    .carousel-inner {
        margin-top: 55px;
    }
}

.box img {
    width: 227px;
    margin-left: 24px;
}

a {
    text-decoration: none;
}

a.p-menubar-button {
    position: absolute;
    right: 5px;
}

ul {
    margin-top: 0;
    margin-bottom: 0 !important;
}

.asc {
    height: 500px;
    width: 75%;
    background-color: rgb(0, 0, 247);
    clip-path: polygon(0 0, 41% 0, 100% 100%, 0 100%);
    margin-top: 72px;
}

.slider {
    display: flex;
}

.slider1 {
    width: 100%;
    height: 736px;
    margin: 0;
    padding: 0;
}

img.d-block.w-100 {
    margin: 0 !important;
}

@media screen and (max-width: 700px) {
    .carousel-indicators [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 300px;
        height: 30px !important;
        padding: 0;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: 0;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 0.5;
        transition: opacity 0.6s ease;
        font-size: 10px;
    }

    .carousel-indicators {
        margin-right: 8%;
        margin-bottom: 0.5rem;
        margin-left: 8%;
    }

    .time {
        display: none;
    }

    .carousel-inner {
        margin-top: 55px;
    }
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 300px;
    height: 43px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: 0;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    line-height: 1;
    z-index: 15;
}

.carousel-indicators .active {
    opacity: 1;
}

.ourheader {
    font-size: 20px;
    font-weight: 700;
}

.ourtatle {
    font-size: 34px;
    font-weight: bold;
    color: #e33855;
}

@media screen and (min-width: 750px) and (max-width: 1000px) {
    .ourtatle {
        font-size: 34px;
        font-weight: bold;
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media screen and (min-width: 0px) and (max-width: 750px) {
    .ourtatle {
        font-size: 20px;
        font-weight: bold;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.box_3 {
    margin-top: 0;
}

.Ourservices {
    padding-top: 50px;
}

.servicesimg1 {
    width: 400px;
    height: 250px;
    margin: 0;
}

.textbox1 {
    position: absolute;
    width: 368px;
    background-image: linear-gradient(1deg, #f34c2dd6, #e1355beb);
    margin-top: 130px;
    margin-left: 16px;
    padding-bottom: 15px;
    height: 222px;
}

.textbox1:hover {
    background-image: linear-gradient(1deg, #f53a18d6, #f71f4eeb);
}

.textbox5 {
    background-image: linear-gradient(1deg, #4b4b4bd6, #ffc107) !important;
}

.textbox5:hover {
    background-image: linear-gradient(1deg, #3b3b3bd6, #eeb200) !important;
}

.box_c1 {
    margin-top: -55px;
    margin-left: 133px;
}

.textbox1 p {
    margin-top: 5px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
}

.textbox1 h6 {
    margin-top: 5px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    padding: 2px;
}

.box_4 {
    margin-top: 9rem;
}

.box_5 {
    margin-top: 9rem;
}

/* <----------------------------------------- service ------------------------------------------------> */

.services_box {
    background-color: #e1355b;
}

.services_img {
    display: flex;
    justify-content: center;
    padding-top: 16px;
}

.card {
    background-color: #ececec;
    border: 0px;
    height: 382px;
}

.card_btn {
    background-image: linear-gradient(90deg, #f34c2d, #e1355b);
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    cursor: pointer;
}

.card_btn:hover {
    background-image: linear-gradient(90deg, #f53716, #f5214f);
    color: #000000;
}

h5.card-title {
    color: #e33855;
    font-weight: bold;
}

.services_boxbtn {
    display: block;
    width: 100%;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .card {
        background-color: #ececec;
        border: 0px;
        height: 405px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .card {
        background-color: #ececec;
        border: 0px;
        height: 455px;
    }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
    .card {
        background-color: #ececec;
        border: 0px;
        height: 576px;
    }
}

@media screen and (min-width: 00) and (max-width: 767px) {
    .card {
        background-color: #ececec;
        border: 0px;
        height: auto;
    }

    .worldwide {
        margin-top: 50px;
        margin-bottom: 200px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .box_4 {
        margin-top: 7rem;
    }

    .box_5 {
        margin-top: 7rem;
    }
}

@media screen and (min-width: 1200px) {
    .col-12.col-md-6.col-lg-6.col-xl-4.col-xxl-4.box_1.box_4 {
        margin-left: 17%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .servicesimg1 {
        width: 360px;
        height: 237px;
    }

    .textbox1 {
        width: 331px;
        height: 232px;
        margin-left: 14px;
    }

    .box_c1 {
        margin-left: 113px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .servicesimg1 {
        width: 458px;
        height: 263px;
        margin: 0;
    }

    .textbox1 {
        width: 427px;
        height: 232px;
    }

    .box_c1 {
        margin-top: -55px;
        margin-left: 163px;
    }

    .box_3 {
        margin-top: 113px;
    }

    .box_5 {
        margin-left: 25%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .servicesimg1 {
        width: 342px;
        height: 232px;
        margin: 0;
    }

    .textbox1 {
        width: 310px;
        height: 250px;
    }

    .box_3 {
        margin-top: 10rem;
    }

    .box_5 {
        margin-left: 25%;
        margin-top: 10rem;
    }

    .box_c1 {
        margin-left: 100px;
    }

    .box_4 {
        margin-top: 10rem;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .servicesimg1 {
        width: 100%;
        max-width: 400px;
    }

    .textbox1 {
        width: 90%;
        max-width: 370px;
    }

    .box_c1 {
        margin-left: 35%;
    }

    .textbox1 {
        margin-left: 0;
        height: 250px;
    }

    .box_2 {
        margin-top: 9rem;
    }

    .box_6 {
        margin-top: 9rem;
    }

    .box_5 {
        margin-top: 9rem;
    }

    .box_4 {
        margin-top: 9rem;
    }
}

.worldwide {
    margin-top: 100px;
    margin-bottom: 100px;
}

.world_img {
    width: 100%;
}

.world_text {
    font-size: 33px;
    font-weight: 700;
}

.world_text1 {
    color: #5d5d5d;
}

@media screen and (min-width: 00) and (max-width: 767px) {
    .worldwide {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .world_img {
        margin-top: 35px;
    }
}

.portfoliotext {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-top: 30px;
}

.portfolioheader {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #e33855;
}

.portfoliotext1 {
    background-image: linear-gradient(90deg, #f34c2d, #e1355b);
    width: 100%;
    height: 200px;
    border-radius: 0 0 30px 30px;
}

img.portfoliobox1 {
    width: 100%;
    height: 100%;
    border-radius: 30px 30px 0 0;
}

.portfoliotext1 h3 {
    text-align: center;
    color: #ffffff;
    padding-top: 12px;
}

.portfoliotext1 p {
    text-align: center;
    padding: 0 25px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
}

.portfolio {
    width: auto;
    background-color: #ececec;
    margin-top: 30px;
}

.portfolio .container {
    padding-bottom: 50px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    img.portfoliobox1 {
        width: 100%;
    }

    .portfoliotext1 {
        width: 100%;
        height: 200px;
    }

    .portfoliotext1 p {
        padding: 0 10px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    img.portfoliobox1 {
        width: 100%;
        height: 260px;
    }

    .portfoliotext1 {
        width: 100%;
        height: 160px;
    }

    .portfolio_box3 {
        margin-top: 22px;
    }

    .portfolio_box1:hover {
        transform: scale(1.05) !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio_box1 {
        margin-top: 14px;
    }

    .portfolio {
        width: auto;
        background-color: #ececec;
        margin-top: 70px;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    img.portfoliobox1 {
        max-width: 516px;
        height: auto;
        width: 100%;
    }

    .portfolio {
        width: auto;
        background-color: #ececec;
        margin-top: 70px;
    }

    .portfoliotext1 {
        max-width: 516px;
        width: 100%;
        height: auto;
    }

    .portfolio_box1:hover {
        transform: scale(1) !important;
    }

    .portfoliotext1 p {
        text-align: center;
        padding: 0 12px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 17px;
    }

    .col-lg-6.col-xl-6.col-xxl-6.portfolio_box1 {
        margin-top: 6px;
    }
}

#Founder .Founder_box {
    background-image: url("./Assets/Img/founder.png");
    background-repeat: no-repeat;
    background-size: cover;
}

#Founder .Founder_box:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

#Founder .Founder_box2 {
    background-image: url("./Assets/Img/founder2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

#Founder .Founder_box2:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

#Founder .paragraph {
    text-indent: 50px;
    font-size: 20px;
    padding-top: 17px;
}

.Founder_text {
    font-weight: bold;
    margin: 25px 0 25px 0 !important;
}

.Founder_header {
    font-size: 36px;
    font-weight: 600;
    color: #e33855;
}

.founder_tag {
    font-size: 18px;
    font-weight: 600;
    color: #e33855;
}

.Founder_tagv {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-top: 17px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    #Founder .paragraph {
        text-indent: 50px;
        font-size: 15px;
        padding-top: 0;
    }

    .Founder_tagv {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 1px;
        padding-top: 10px;
    }
}

// @keyframes shine {
//     0% {
//         background-position: 0;
//     }

//     60% {
//         background-position: 600px;
//     }

//     100% {
//         background-position: 1000px;
//     }
// }

button.demo {
    position: absolute;
    right: 0;
    margin-top: -1px;
    margin-right: 12px;
    border: none;
    background-color: #fff;
    padding: 0;
    z-index: 1;
    a {
        color: #000;
        padding: 5px 10px 5px 10px !important;
    }
    &:hover {
        background-image: linear-gradient(90deg, #f34c2d, #e1355b);
    }
}

// @media screen and (min-width: 556px) {
//     .animate-charcter {
//         text-transform: uppercase;
//         background-image: linear-gradient(-225deg, #ffffff 0%, #ffffff 29%, #ffffff 67%, #ffffff 100%);
//         background-size: auto auto;
//         background-clip: border-box;
//         background-size: 200% auto;
//         color: #fff;
//         background-clip: text;
//         color: transparent;
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         animation: textclip 2s linear 1 alternate-reverse;
//     }

//     @keyframes textclip {
//         0% {
//             background-position: 200% center;
//             background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #1323ff 67%, #fff800 100%);
//         }

//         100% {
//             background-image: linear-gradient(-225deg, #ffffff 0%, #ffffff 29%, #ffffff 67%, #ffffff 100%);
//         }
//     }
// }

// .slideUp {
//     animation-name: slideUp;
//     animation-name: slideUp;
//     animation-duration: 1s;
//     animation-duration: 1s;
//     visibility: visible;
// }

// @keyframes slideUp {
//     0% {
//         opacity: 0;
//         transform: translateY(70%);
//     }

//     100% {
//         opacity: 1;
//         transform: translateY(0%);
//     }
// }

// .text--slide .char:nth-of-type(5) {
//     transition-delay: 0s;
// }

// .text--slide .char:nth-of-type(4) {
//     transition-delay: 0.1s;
// }

// .text--slide .char:nth-of-type(3) {
//     transition-delay: 0.2s;
// }

// .text--slide .char:nth-of-type(2) {
//     transition-delay: 0.3s;
// }

// .text--slide .char:nth-of-type(1) {
//     transition-delay: 0.4s;
// }

// .text--slide [data-scroll="out"] .char {
//     opacity: 0;
//     -webkit-transform: translate(-300%, 0) skewX(20deg);
//     transform: translate(-300%, 0) skewX(20deg);
// }

.home video#video {
    width: 100%;
}

.home_text {
    position: absolute;
    color: #fff;
    font-size: 35px;
    z-index: 13;
    top: 50%;
    left: 10%;
    transform: translate(-10%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: 768px) and (max-width: 1250px) {
        font-size: 25px;
    }

    @media screen and (min-width: 0) and (max-width: 768px) {
        font-size: 18px;
        z-index: 11;
        padding: 0 0 0 12px;
    }
}

.home_text1 {
    line-height: 1;
    font-size: 25px;

    @media screen and (min-width: 0) and (max-width: 550px) {
        font-size: 20px;
    }
}

.home button {
    padding: 0;
    background: transparent;
    border: 0px solid #fff;
}

.home button a {
    display: block;
    margin: 0;
    padding: 14px;
    background: transparent;
    font-size: 17px;
    color: #fff;
    border: 1px solid #fff;
}

.home button a:hover {
    background-image: linear-gradient(90deg, #f34c2d, #e1355b);
    border: 1px solid #e1355b;
}

@media screen and (min-width: 0) and (max-width: 768px) {
    .home {
        margin-top: 70px;
    }

    .home button {
        background: transparent;
        color: #fff;
        padding: 5px;
        font-weight: 700;
    }

    .home button a {
        padding: 7px;
        font-size: 10px;
    }
}

@media screen and (min-width: 769px) {
    .dis_none_mob {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .dis_none_web {
        display: none;
    }
}

// <----  about ----------------------------------------------->

.about {
    margin-top: 50px;
    margin-bottom: 10px;
    button {
        background: transparent;
        border: 2px solid #000;
        color: #000;
        padding: 15px;
        width: auto;
        font-size: 16px;
        font-weight: 800;

        &:hover {
            background-image: linear-gradient(90deg, #f34c2d, #e1355b);
            border: 2px solid #e1355b;
        }
    }
}

.aboutbox {
    width: 100%;
    height: auto;
}

.about_p {
    text-align: center;
    padding-top: 35px;
    font-weight: bold;
    font-size: 20px;

    @media screen and (min-width: 0px) and (max-width: 625px) {
        font-size: 10px;
    }
}

.about_h {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    color: #e33855;

    @media screen and (min-width: 0px) and (max-width: 625px) {
        font-size: 20px;
    }
}

p.about_par {
    color: #5d5d5d;
    text-align: justify;
}

.about_text {
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

// .arrow {
//     margin-top: 40px;
//     padding: 0;
// }

// .arrowcolor {
//     color: #ffffff;
// }

// .arrowtext {
//     background: linear-gradient(to right, #ff1111e3 5%, #262626 5%);
//     width: 100%;
//     margin-left: 9px;
//     height: 40px;
//     line-height: 2.5;
//     padding-left: 25px;
//     color: #fff;

//     @media screen and (min-width: 0px) and (max-width: 625px) {
//         width: auto;
//         margin-left: 0;
//         height: 40px;
//         line-height: 2.5;
//         padding-left: 30px;
//         color: #fff;
//     }
// }

// .arrowcoulm {
//     display: flex;
//     justify-content: center;

//     @media screen and (min-width: 0) and (max-width: 870px) {
//         display: none;
//     }
// }

.about_img11 {
    display: flex;
    align-items: center;
    width: 300px;
    margin: auto;
    top: 33%;
    position: relative;
    transform: translateY(-33%);
    @media screen and (min-width: 0) and (max-width: 767px) {
        display: none;
    }
}

.aboutus {
    font-size: 30px;
    font-weight: 800;
    @media screen and (min-width: 0px) and (max-width: 768px) {
        font-size: 30px;
        font-weight: 800;
        padding: 0px;
        text-align: center;
    }
}

.about_img {
    width: 100%;
    @media screen and (min-width: 0px) and (max-width: 768px) {
        height: 100%;
    }
}

// @media screen and (min-width: 870px) {
//     .arrowrow {
//         display: none;
//     }
// }

// @media screen and (min-width: 625px) and (max-width: 870px) {
//     .arrowrow {
//         display: flex;
//         margin: 0 15px;
//     }
// }

.about_view {
    @media screen and (min-width: 0px) and (max-width: 768px) {
        text-align: center;
        margin-top: 10px;
    }
}

// <----------- footer in privacy ----------------------------------------------------------------

.refund_img {
    background-image: url(".//Assets/Img/refund.svg");
    width: 100%;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.refundpolicy {
    margin: 100px 0px 30px 00px;
    p,
    li {
        font-size: 15px;

        @media screen and (min-width: 0) and (max-width: 576px) {
            font-size: 15px;
            list-style: none;
        }
    }
}

.p_text {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 10;
    width: 100%;
    text-align: center;
}

.p_text2 {
    margin-bottom: 0;
    line-height: 1.2;
    @media screen and (min-width: 0px) and (max-width: 767px) {
        font-size: 32px;
    }
}

.p_text3 {
    font-size: 22px;
    font-weight: 600;
    z-index: 10;
    text-align: center;

    @media screen and (min-width: 0px) and (max-width: 767px) {
        font-size: 18px;
    }
    @media screen and (min-width: 0px) and (max-width: 375px) {
        text-align: center;
    }
}

.term_img {
    background-image: url("./Assets/Img/term.svg");
    width: 100%;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.termandcodition {
    margin: 110px 0px 30px 0px;
    p {
        font-size: 15px;
    }

    & ::marker {
        font-weight: bold;
    }
}

.tearm_header {
    text-align: center;
    font-size: 20px;

    strong {
        font-size: 25px;
    }
}

.t_bottom {
    margin-bottom: 0;
}

.t_dot {
    list-style: initial;
}

.t_color {
    font-weight: 600;
}

.privacy_img {
    background-image: url("./Assets/Img/privatepolicy.svg");
    width: 100%;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.privacy_text {
    margin-top: 101px;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.Privacyios_img {
    background-image: url("./Assets/Img/android.svg");
    width: 100%;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.p_img1 {
    position: relative;

    &::before {
        background-color: #242424;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0.75;
        z-index: 10;
        height: 100%;
    }
}

.home_btn {
    color: #fff;
}

.home_btn:hover {
    color: #fff;
}
