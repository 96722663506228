@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&family=Hind:wght@400;600;700&family=Inconsolata:wght@700&family=PT+Serif&family=Poppins&family=Righteous&family=Roboto&family=Roboto+Slab:wght@500&display=swap');


.privacy {
    cursor: pointer;
    color: #fff;
    font-size: 13px;
}

.privacy:hover {
    color: #E33855;
    opacity: 1;
    margin-left: 5px;
    transition: all 0.3s ease-out 0s;
}

.privacy:hover::before {
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.privacy:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #E33855;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform 0.4s cubic-bezier(.74, .72, .27, .24);
    border-bottom: 2px solid #E33855;
}


.footertext {
    font-family: var(--primary-font-family);
    color: #fff;
    width: fit-content;
    position: relative;
}

.f_fontfamily {
    font-size: 20px;
    margin-top: 74px;
}

.footercopyright {
    font-family: var(--primary-font-family);

}

.footer_logotext {
    font-family: var(--primary-font-family);

}


#footer {
    background: #000;
    position: relative;
}

.joo {
    color: #fff;
    margin-left: 10px;
    font-family: var(--primary-font-family);
    font-weight: bold;
    z-index: 1;
    position: relative;
}

.joo:hover {
    color: #b1b1b1;
    text-decoration: none;
}

.facebook {
    display: flex;
    justify-content: flex-end;
}

.linkd {
    margin-left: 0 !important;
}

.linkede {
    width: 40px;
    margin-left: 16px;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.linkede:hover {
    transform: scale(1.1);
}

@media screen and (min-width: 0px) and (max-width:767px) {
    .facebook {
        display: flex;
        justify-content: center;
    }
}

.f_map {
    padding-top: 15px;
    display: flex;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.f_map2 {
    padding-top: 15px;
    display: flex;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.f_mapimg {
    height: 22px;
}

.f_adress {
    padding-left: 15px;
    color: #ffffff;
    font-family: var(--primary-font-family);
    font-size: 13px;
    text-decoration: none; 
}

.f_adress2 {
    padding-left: 15px;
    color: #ffffff;
    font-family: var(--primary-font-family);
    margin-bottom: 0rem;
    font-size: 13px;
    text-decoration: none; 
}

.lineheight {
    line-height: 2.5;
}

.f_displayflex {
    display: flex;
    justify-content: space-between;
}

.f_gmail {
    display: flex;
    z-index: 1;
    position: relative;
    padding-top: 15px;
}

.phone {
    display: flex;
    z-index: 1;
    position: relative;
    padding-top: 35px;
}


.f_gmail_text {
    line-height: 0.5;
    padding-left: 15px;
    color: #ffffff;
    padding-top: 7px;
}

.phone_text {
    line-height: 0.5;
    padding-left: 15px;
    color: #ffffff;
    padding-top: 7px;
}

p.f_gmail1 {
    cursor: pointer;
    font-family: var(--primary-font-family);
    font-size: 13px;
    text-decoration: none !important; 
}

.phone1 {
    cursor: pointer;
    font-family: var(--primary-font-family);
    font-size: 13px;
    text-decoration: none; 
}

p.f_gmail2 {
    cursor: pointer;
    font-family: var(--primary-font-family);
    font-size: 13px;
    text-decoration: none; 
}

.phone2 {
    cursor: pointer;
    font-family: var(--primary-font-family);
    font-size: 13px;
    text-decoration: none; 
}

.mapimg {
    width: 22px;
    height: 22px;
}

@media screen and (min-width: 992px) and (max-width:1200px) {
    p.f_gmail1 {
        font-size: 12px;
    }

    p.f_gmail2 {
        font-size: 12px;
    }
}

.f_contactus {
    color: #fff;
    font-size: 20px;
    margin-top: 10px;
}

.f_office {
    color: #fff;
    font-size: 20px;
    margin-top: 10px;
}

.privacy a {
    color: #fff;
}

.privacy a:hover {
    color: #E33855;
}

@media screen and (min-width: 0px) and (max-width:767px) {
    .f_fontfamily {
        font-size: 20px;
        margin-top: 0px;
    }
}